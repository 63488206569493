@import "../globals";

.Controls {
    height: $sectionsL;
    width: $sectionsL;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 2fr 1fr 1fr 1fr;
    gap: 1.5rem;
}
