@import "../../globals";

.BPMWrapper {
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;

    .BPM {
        background-color: transparent;
        color: $light;
        margin-left: 0.5rem;
        font-size: 1rem;
        width: 10%;
        border: none;
        outline: 0px;
    }

    input[type="number"] {
        -moz-appearance: textfield;
        appearance: textfield;
        margin: 0;
        margin-left: 0.5rem;
    }

    input[type="number"]::-webkit-inner-spin-button,
    input[type="number"]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    .BpmIcons {
        display: grid;
        transform: translate(-10px, 0);
        svg {
            fill: $light;
            width: 6px;
            cursor: pointer;
        }
        svg:first-of-type {
            transform: rotate(-90deg);
        }
        svg:last-of-type {
            transform: rotate(90deg);
        }
    }
}
