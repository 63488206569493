@import "../globals";

.Drumpad {
    width: max-content;
    height: max-content;
    background-color: $light;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: $transNormal;
    transition: background-color ease-in-out 0.25s;
    cursor: pointer;
}

.DrumpadKey,
.DrumpadName {
    display: block;
    font-size: 1.25rem;
    font-weight: 600;
    text-transform: uppercase;
    padding: 0.5rem;
    user-select: none;
}

.DrumpadName {
    font-size: 1rem;
}

.DrumpadActive {
    scale: 0.975;
    box-shadow: $glow;
    filter: brightness(1.1);
}
