@import "../globals";

.Transport {
    height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1.5rem;
}

.TransportButtonWrapper {
    display: flex;
    flex-direction: column;
    row-gap: 0.5rem;
}

.TransportButton {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 0.5rem;

    svg {
        width: 14px;
    }
}

.Play {
    svg {
        fill: $green;
    }
}
.Pause {
    svg {
        fill: $yellow;
    }
}
.Stop {
    svg {
        fill: $orange;
    }
}

.ButtonLabel {
    display: flex;
    justify-content: center;
    box-sizing: border-box;
    width: 100%;
    color: $light;
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    user-select: none;
}

.TransportVolume {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    color: $light;
}

.TransportTrigger {
    box-shadow: $glow;
    filter: brightness(1.1);
}
