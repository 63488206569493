@import url("https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;700&display=swap");

// Fonts

$sans: "Lato", sans-serif;

// Colors

$light: #e5e5e5;
$mid: #a0a0a0;
$dark: #1a1a1a;
$green: #81b29a;
$orange: #e07a5f;
$yellow: #f2cc8f;

// Borders

$borderColor: rgb(213, 220, 226) rgb(213, 220, 226) rgb(184, 194, 204);
$borderNormal: 2px solid;
$radiusL: 10px;

// Section Sizes

$sectionsL: 520px;
$maschineWL: 1200px;
$maschineHL: 600px;

// Transitions

$transNormal: all ease-in-out 0.1s;

// Shadows

$glow: 0px 0px 15px -3px rgba(255, 243, 202, 0.5);
$shadow: 0px 0px 15px 1px rgba(0, 0, 0, 0.5);

// Breakpoints

$brWL: 1340px;
$brHL: 650px;
