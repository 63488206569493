@import "./globals";

* {
    padding: 0;
    margin: 0;
    font-family: $sans;
}

body {
    background-color: $dark;
}

button {
    background-color: transparent;
    color: $light;
    cursor: pointer;

    border-radius: $radiusL;
    border: $borderNormal;
    border-color: $borderColor;
}

button:active {
    box-shadow: $glow;
    filter: brightness(1.1);
}

a {
    color: $mid;
    text-decoration: none;
    font-size: 1rem;
    transition: $transNormal;
}

a:hover {
    color: $light;
}

// App

.App {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 100vw;
    height: 100vh;
}

@media screen and (max-width: $brWL), screen and (max-height: $brHL) {
    .App {
        transform: scale(0.85);
    }
}

@media screen and (max-width: 1030px), screen and (max-height: 540px) {
    .App {
        transform: scale(0.75);
    }
}

@media screen and (max-width: 910px), screen and (max-height: 470px) {
    .App {
        transform: scale(0.6);
    }
}

@media screen and (max-width: 730px), screen and (max-height: 380px) {
    .App {
        transform: scale(0.5);
    }
}

// Volume Knob

.knob-markers {
    stroke: $mid;
}

.knob-track {
    stroke: $green;
}

.knob-value {
    fill: $light;
}
