@import "./globals";

.Maschine {
    width: $maschineWL;
    height: $maschineHL;
    padding: 3rem;
    box-sizing: border-box;

    display: flex;
    column-gap: 3rem;
    justify-content: center;
    align-items: center;

    border-radius: $radiusL;
    border: $borderNormal;
    border-color: $borderColor;

    animation: fadeIn ease 2s forwards;
}

@keyframes fadeIn {
    from {
        transform: perspective(1) rotateX(0);
        opacity: 0;
    }
    to {
        transform: perspective(75em) rotateX(18deg);
        box-shadow: rgba(22, 31, 39, 0.42) 0px 60px 123px -25px,
            rgba(19, 26, 32, 0.08) 0px 35px 75px -35px;
        opacity: 1;
    }
}

@media screen and (max-width: $brWL), screen and (max-height: $brHL) {
    .Maschine {
        animation: none;
    }
}
