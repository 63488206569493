@import "../../globals";

.Volume {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 0.5rem;
}

.VolumeKnob {
    width: 62px;
    height: 62px;
    background-color: transparent;

    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
    color: $light;
}

.VolumeLabel {
    text-transform: uppercase;
    font-size: 1rem;
    font-weight: 600;
}
