@import "../globals";

.Drumpads {
    height: $sectionsL;
    width: $sectionsL;
    box-sizing: border-box;
    padding: 1rem;

    border-radius: $radiusL;
    border: $borderNormal;
    border-color: $borderColor;
}

.DrumpadsGrid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    grid-gap: 1rem;
    height: 100%;
}
