@import "../globals";

.Reset {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    background-color: transparent;

    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 1.5rem;
}

.ResetButton {
    padding: 1rem;
    font-size: 1rem;
    font-weight: 600;
    text-transform: uppercase;
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;
}
